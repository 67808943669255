import { Component, HostBinding, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MqttService } from '../../../services/mqtt.service';
import { DevicesService } from '../../../services/devices.service';
import { StatsService } from '../../../services/stats.service';
import { OeeParams } from '../../../models/stats';
import * as moment from 'moment/moment';
import { Device } from '../../../models/device';
import { Router } from '@angular/router';
import { StopService } from "../../../services/stop-history.service";
import { Stop } from "../../../models/stop";
import { Timer } from "./Timer";
import { SessionService } from "../../../services/session.service";
import { OrderService } from "../../../services/order.service";
import { Session } from '../../../models/session';
import { Phase } from 'app/models/phase';
import { Activity } from 'app/models/activity';
import { Observable } from 'rxjs/Rx';
import { SnackbarType } from 'app/models/snackbar';
import { SnackbarService } from 'app/services/snackbar.service';
import { MqttUtils } from 'app/services/mqtt.utils';

@Component({
    selector: 'device-box',
    templateUrl: 'device-box.component.html',
    styleUrls: ['./device-box.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DeviceBoxComponent implements OnInit, OnDestroy {
    @HostBinding('style.pointer-events') get pointerEvents(): string {
        if (this.device.isMultiOrderEnabled()) {
            return 'none';
        } else {
            return 'auto';
        }
    };

    @Input() device: Device;
    @Input() mqttService: MqttService;
    connected: boolean = false;
    sessionEmpty: boolean = false;
    oeeTimer: Timer;
    oee: OeeParams;
    stopTimer: Timer;
    stopClock = -1;
    productionTimer: Timer;
    productionClock = -1;
    productionBeginAt;
    changeOverTimer: Timer;
    endProductionTimer: Timer;
    changeOverClock = -1;
    endProductionClock = -1;
    activeStop: Stop;
    sessions: Session[];

    phase: Phase;
    activity: Activity;
    deviceUndefined: boolean;

    totalActivityTime = null;
    activityTime = null;

    activityTimer: Timer;
    activityClock = -1;
    activityClockMinutes = -1;

    processTimer: Timer;
    processClock = -1;
    processClockMinutes = -1;
    onMessage;
    pieces: number = 0;
    targetPieces: number = null;
    infoLabel: string;

    constructor(
        private _deviceService: DevicesService,
        private _stopService: StopService,
        private _statsService: StatsService,
        private router: Router,
        private _sessionService: SessionService,
        private _orderService: OrderService,
        private _uiService: SnackbarService,
        private _mqttUtils: MqttUtils
    ) {
        const tenMinutes = 10 * 60 * 1000;
        this.oeeTimer = new Timer({
            onStart: async () => {
                this.oee = await this._statsService.getAggregateOee(this.device.getBegin(), moment().toISOString(), [this.device.id]);
                this.oee.oee *= 100;
            },
            onStop: () => {
                this.oee = undefined
            },
            timeoutMs: tenMinutes
        });

        const oneSecond = 1000;

        //     this.productionTimer = new Timer({
        //         onStart: () => {
        //             this.productionClock += oneSecond;
        //         },
        //         onStop: () => this.productionClock = -1,
        //         beforeFirstStart: () => {
        //             if (this.device.isProducing()) {
        //                 const { totalTime } = this.device.Session;
        //                 const { downtimeWithPlanned } = this.device;
        //                 this.productionClock = moment(totalTime).valueOf() - moment(downtimeWithPlanned).valueOf();
        //             }
        //         },
        //         timeoutMs: oneSecond
        //     });

        this.productionTimer = new Timer({
            onStart: () => {
                this.productionClock = (moment().valueOf() - moment(this.productionBeginAt).valueOf()) - moment(this.device.downtimeWithPlanned).valueOf();
            },
            onStop: () => this.productionClock = -1,
            beforeFirstStart: () => {
                if (this.device.isProducing() && !this.device.isProcessMode()) {
                    const { totalTime } = this.device.Session;
                    this.productionBeginAt = moment().valueOf() - moment(totalTime).valueOf();
                }
            },
            timeoutMs: oneSecond
        });

        // this.processTimer = new Timer({
        //     onStart: () => {
        //         this.processClock = (moment().valueOf() - moment(this.productionBeginAt).valueOf()) - moment(this.device.downtimeWithPlanned).valueOf();
        //     },
        //     onStop: () => this.processClock = -1,
        //     beforeFirstStart: () => {
        //         if (this.device.isProducing() && this.device.isProcessMode()) {
        //             const process = this.device.WorkProcess;
        //             this.phase = process.WorkProcessPhases.find(p => p.id === this.device.Session.WorkProcessCurrentActivityLog.phaseId);
        //             this.activity = this.phase.ActivityUnits.find(a => a.id === this.device.Session.WorkProcessCurrentActivityLog.activityId);

        //             if (this.phase.targetTimeInMinutes) {
        //                 this.totalActivityTime = this.phase.targetTimeInMinutes * 60 * 1000
        //             } else {
        //                 let minutes;
        //                 this.phase.ActivityUnits.map(a => {
        //                     minutes += a.ProductionActivity.targetTimeInMinutes * 60 * 1000;
        //                 });
        //                 this.totalActivityTime = minutes;
        //             }
        //             const { totalTime } = this.device.Session;
        //             this.productionBeginAt = moment(this.device.Session.WorkProcessCurrentActivityLog.beginAt).valueOf() - moment(this.device.Session.beginAt).valueOf();
        //             this.productionBeginAt = moment().valueOf() - moment(totalTime).valueOf();
        //             this.processClock = (moment().valueOf() - moment(this.productionBeginAt).valueOf()) - moment(this.device.downtimeWithPlanned).valueOf();
        //             this.processClockMinutes = moment.duration(this.processClock).asMinutes();
        //         }
        //     },
        //     timeoutMs: oneSecond
        // });

        this.activityTimer = new Timer({
            onStart: () => {
                if (this.device.isProducing() && this.device.isProcessMode()) {
                    this.activityClock = moment().valueOf() - moment(this.device.Session.WorkProcessCurrentActivityLog.beginAt).valueOf();
                    this.activityClockMinutes = moment.duration(this.activityClock).asMinutes();
                }
            },
            onStop: () => this.activityClock = -1,
            beforeFirstStart: () => {
                this.activityTime = this.activity.ProductionActivity.targetTimeInMinutes * 60 * 1000;
            },
            timeoutMs: oneSecond
        });

        this.changeOverTimer = new Timer({
            onStart: () => {
                let timeNow = moment().valueOf();
                let offset = 0
                if (this.sessions.length > 0) {
                    let lastSession = this.sessions[this.sessions.length - 1];
                    offset = moment(lastSession.endAt).valueOf();
                } else {
                    this.sessionEmpty = true;
                }
                this.changeOverClock = timeNow - offset;
            },
            onStop: () => {
                this.changeOverClock = -1;
                this.activeStop = undefined;
            },
            beforeFirstStart: async () => {
                if (this.device.isChangeOver()) {
                    
                    let startDate = moment(new Date()).add(-4, 'days').toISOString();
                    let endDate = moment(new Date()).toISOString();
                    this.sessions = await this._sessionService.getAll(startDate, endDate, this.device.id, null);
                }
            },
            timeoutMs: oneSecond
        });

        this.endProductionTimer = new Timer({
            onStart: () => {
                let timeNow = moment().valueOf();
                let offset = 0
                if (this.sessions.length > 0) {
                    let lastSession = this.sessions[this.sessions.length - 1];
                    offset = moment(lastSession.endAt).valueOf();
                } else {
                    this.sessionEmpty = true;
                }
                this.endProductionClock = timeNow - offset;
            },
            onStop: () => {
                this.endProductionClock = -1;
                this.activeStop = undefined;
            },
            beforeFirstStart: async () => {
                if (this.device.isEndProduction()) {
                    
                    let startDate = moment(new Date()).add(-4, 'days').toISOString();
                    let endDate = moment(new Date()).toISOString();
                    this.sessions = await this._sessionService.getAll(startDate, endDate, this.device.id, null);
                }
            },
            timeoutMs: oneSecond
        });

        this.stopTimer = new Timer({
            onStart: () => this.stopClock = moment().valueOf() - moment(this.activeStop.beginAt).valueOf(),
            onStop: () => {
                this.stopClock = -1;
                this.activeStop = null;
            },
            beforeFirstStart: async () => {
                this.activeStop = await this._stopService.getActive(this.device.id);
                this.stopClock = moment().valueOf() - moment(this.activeStop.beginAt).valueOf();
            },
            timeoutMs: oneSecond
        });
    }

    async ngOnInit() {
        await this.fetchDevice();
        await this.connectToMqtt();
        await this.subscribeToDataChannel();
        await this.waitForPieces();

        Observable.interval(1000).subscribe(() => {
            // this.processClockMinutes = moment.duration(this.processClock).asMinutes();
            if (this.device.isProcessMode() && this.activityClock) {
                this.activityClockMinutes = moment.duration(this.activityClock).asMinutes();
            }
        })
    }

    async ngOnDestroy() {
        if (this.oeeTimer) {
            this.oeeTimer.reset();
        }
        
        if (this.productionTimer) {
            this.productionTimer.reset();
        }

        if (this.processTimer) {
            this.processTimer.reset();
            this.activityTimer.reset();
        }

        if (this.changeOverTimer) {
            this.changeOverTimer.reset();
        }

        if (this.endProductionTimer) {
            this.endProductionTimer.reset();
        }

        if (this.stopTimer) {
            this.stopTimer.reset();
        }
        if (this.onMessage) {
            this.onMessage.unsubscribe();
        }
        if (this.connected) {
            await this.mqttService.unsubscribe(this._mqttUtils.computeTopicForDevice(this.device.id));
            await this.mqttService.unsubscribe(this._mqttUtils.computeTopicEndpoint(this.device.id));
        }
    }


    async connectToMqtt() {
        this.connected = await this.mqttService.connectWebUser();

        if (!this.connected) {
            this._uiService.showSnackbar('Error retrieving devices status.\nPlease try again later.',SnackbarType.error, { verticalPosition: 'bottom'});
        }
    }

    async subscribeToDataChannel() {
        console.log(`subscribe to ${this._mqttUtils.computeTopicEndpoint(this.device.id)}`);
        console.log(`subscribe to ${this._mqttUtils.computeTopicForDevice(this.device.id)}`);
        await this.mqttService.subscribe(this._mqttUtils.computeTopicEndpoint(this.device.id));
        await this.mqttService.subscribe(this._mqttUtils.computeTopicForDevice(this.device.id));
    }

    async waitForPieces() {
        if (this.onMessage) {
            return;
        }
        this.onMessage = this.mqttService.onMqttMessage.subscribe(async (message) => {
            const topic = message.topic;
            const payload = message.payload;
            if (message.topic.includes(`endpoint/${this.device.id}`)) {
                if (topic.includes("pieces")) {
                    const message = JSON.parse(payload.toString());
                    this.pieces += message.value;
                } else if (topic.includes("state") || topic.includes("production/start/order") ||
                           topic.includes("production/end")) {
                    await this.fetchDevice();
                }
            } else if (topic === this._mqttUtils.computeTopicForDevice(this.device.id)) {
                const commandType = payload.readUIntBE(0, 1);
                if (commandType === 40) {
                    await this.fetchDevice();
                }
            }
        });
    }

    async fetchDevice() {
        this.device = await this._deviceService.getDevice(this.device.id);
        const { pieces, targetPieces, code, productCode } = this._deviceService.getDeviceDataFormatted(this.device);

        this.infoLabel = code ? code : productCode ? productCode : "";
        this.pieces = pieces;
        this.targetPieces = targetPieces;

        if (this.device.isProducing() && this.device.isProcessMode()) {
            const process = this.device.WorkProcess;
            this.phase = process.WorkProcessPhases.find(p => p.id === this.device.Session.WorkProcessCurrentActivityLog.phaseId);
            if (this.phase && this.phase.description.length > 0) {
                this.activity = this.phase.ActivityUnits.find(a => a.id === this.device.Session.WorkProcessCurrentActivityLog.activityId);
                if (!this.activity && !this.activity.description) { this.initUndefined(); }

                let minutes = 0;
                for (const phase of process.WorkProcessPhases) {
                    if (phase.WorkProcessesPhases.order <= this.phase.WorkProcessesPhases.order) {
                        if (phase.id === this.device.Session.WorkProcessCurrentActivityLog.phaseId) {
                            for (const a of phase.ActivityUnits) {
                                if (a.PhasesActivityUnits.order < this.activity.PhasesActivityUnits.order) {
                                    minutes += a.ProductionActivity.targetTimeInMinutes;
                                }
                            }
                        } else {
                            phase.ActivityUnits.map(a => minutes += a.ProductionActivity.targetTimeInMinutes);
                        }
                    }
                }

                // this.totalActivityTime = minutes === this.phase.targetTimeInMinutes ? minutes * 60 * 1000 : this.phase.targetTimeInMinutes * 60 * 1000;

                this.totalActivityTime = minutes * 60 * 1000;

                // if (this.phase.targetTimeInMinutes) {
                //     this.totalActivityTime = this.phase.targetTimeInMinutes * 60 * 1000
                // } else {
                //     let minutes;
                //     this.phase.ActivityUnits.map(a => {
                //         minutes += a.ProductionActivity.targetTimeInMinutes * 60 * 1000;
                //     });
                //     this.totalActivityTime = minutes;
                // }
                this.processClock = moment(this.device.Session.WorkProcessCurrentActivityLog.beginAt).valueOf() - moment(this.device.Session.beginAt).valueOf();

                if (this.processClock < 0) {
                    this.processClock = 0;
                }

                this.processClockMinutes = moment.duration(this.processClock).asMinutes();
            } else {
                this.initUndefined();
            }
        }

        this.oeeTimer.reset(this.device.isProducing() || this.device.isStopped());
        this.stopTimer.reset(this.device.isStopped() || this.device.isChangeActivity());
        this.productionTimer.reset(this.device.isTimerMode() && this.device.isProducing() || this.device.isProducing() && !this.device.isProcessMode());
        // this.processTimer.reset(this.device.isProcessMode());
        this.activityTimer.reset(this.device.isProcessMode());
        this.changeOverTimer.reset(this.device.isChangeOver());
        this.endProductionTimer.reset(this.device.isEndProduction());
    }

    initUndefined() {
        this.deviceUndefined = true;
    }

    onClick() {
        this.router.navigate(['realtime/device', this.device.id]);
    }
}
