import { DateTimeFormatPipe } from './pipes/dateTimeFormat.pipe';
import { ProductService } from './services/product.service';
import { LimitToPipe } from './pipes/limitTo.pipe';
import { SlicePipe } from './pipes/slice.pipe';
import { ActiveFiltersService } from './services/active-filters.service';
import { NavBarComponent } from './components/ui/navbar/navbar.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule, Routes } from '@angular/router';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {
    MatFormFieldModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatInputModule,
    MatListModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatIconModule,
} from '@angular/material';

import { AppComponent } from './app.component';
import { FilterBarComponent } from './components/ui/filter-bar/filter-bar.component';

import { DocumentViewComponent } from './components/pages/document-view/document-view.component';
import { LoginComponent } from './components/pages/login/login.component';

import { SessionService } from 'app/services/session.service';
import { LoginService } from './services/login.service';
import { HttpService } from './services/http.service';
import { DevicesService } from './services/devices.service';
import { NavbarService } from './services/navbar.service';
import { ThemeService } from './services/theme.service'
import { CompanyService } from './services/company.service'
import { ControlService } from './services/control.service'
import { OrderService } from './services/order.service';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { CalendarModule } from 'primeng/primeng';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TooltipComponent } from './components/ui/tooltip/tooltip.component';
import { MqttService } from './services/mqtt.service';
import { UiService } from './services/ui.service';
import { FilterArrayPipe } from './pipes/filterArray.pipe';
import { TimerPipe } from './pipes/timer.pipe';
import { LongDurationPipe } from './pipes/longDuration.pipe';
import { Daterangepicker } from 'ng2-daterangepicker';

import localeFr from "@angular/common/locales/fr";
import localeEl from "@angular/common/locales/el";
import localeDe from "@angular/common/locales/de";
import localeIt from "@angular/common/locales/it";
import { registerLocaleData } from '@angular/common';
import { PdfViewerModule } from "ng2-pdf-viewer";
import { PinchZoomModule } from 'ngx-pinch-zoom';
import {AggregatesComponent} from "./components/pages/aggregates/aggregates.component";
import {StatsService} from "./services/stats.service";
import {DeviceBoxComponent} from "./components/ui/device-box/device-box.component";
import {StopService} from "./services/stop-history.service";
import {DeviceProducingComponent} from "./components/ui/device-producing/device-producing.component";
import {DeviceStoppedComponent} from "./components/ui/device-stopped/device-stopped.component";
import {DeviceChangeoverComponent} from "./components/ui/device-changeover/device-changeover.component";
import {DeviceEndproductionComponent} from "./components/ui/device-endproduction/device-endproduction.component";
import {DeviceDetailsComponent} from "./components/pages/device-details/device-details.component";
import {DeviceDisconnectedComponent} from "./components/ui/device-disconnected/device-disconnected.component";
import {ProgressBarComponent} from "./components/ui/progress-bar/progress-bar.component";
import { ProductDetailBarComponent } from './components/ui/product-detail-bar/product-detail-bar.component';
import { QualityControlComponent } from './components/pages/quality-control/quality-control.component';
import { ControlDetailsComponent } from './components/pages/control-details/control-details.component';
import { PopupErrorComponent } from './components/ui/popup-error/popup-error.component';
import { PopupNotesComponent } from './components/ui/popup-notes/popup-notes.component';
import { TaskViewComponent } from './components/ui/task-view/task-view.component';
import { PluginService } from './services/plugin.service';
import { TaskService } from './services/task.service';

import { WorkProcessService } from './services/workprocess.service';
import { PageWithLoader } from './components/pages/page-with-loader';
import { DeviceChangeactivityComponent } from './components/ui/device-changeactivity/device-changeactivity.component';
import { LocalStorageUtils } from './services/localstorage.utils';
import { UserUtils } from './services/users.utils';
import { MqttUtils } from './services/mqtt.utils';
import { SnackbarService } from './services/snackbar.service';
import { EnvConfigurationService } from './services/env-config.service';


registerLocaleData(localeFr);
registerLocaleData(localeEl);
registerLocaleData(localeDe);
registerLocaleData(localeIt);

const appRoutes: Routes = [
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'documentview',
        component: DocumentViewComponent
    },
    {
        path: 'qualitycontrol',
        component: QualityControlComponent
    },
    {
        path: 'qualitycontrol/control/:id',
        component: ControlDetailsComponent
    },
    {
        path: 'realtime',
        component: AggregatesComponent
    },
    {
        path: 'realtime/device/:id',
        component: DeviceDetailsComponent
    },
    {
        path: '',
        redirectTo: LoginComponent.rootPagePath,
        pathMatch: 'full'
    }
];

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export function initConfig(configService: EnvConfigurationService) {
    // load the config file in this function
    return () => configService.load()
}
@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
	    DocumentViewComponent,
        AggregatesComponent,
        NavBarComponent,
        FilterBarComponent,
        LimitToPipe,
        SlicePipe,
        DateTimeFormatPipe,
        FilterArrayPipe,
        TimerPipe,
        LongDurationPipe,
        TooltipComponent,
        DeviceBoxComponent,
        DeviceDetailsComponent,
        DeviceProducingComponent,
        DeviceStoppedComponent,
        DeviceChangeoverComponent,
        DeviceEndproductionComponent,
        DeviceDisconnectedComponent,
        ProgressBarComponent,
        ProductDetailBarComponent,
        QualityControlComponent,
        ControlDetailsComponent,
        PopupErrorComponent,
        PopupNotesComponent,
        TaskViewComponent,
        PageWithLoader,
        DeviceChangeactivityComponent
    ],
    imports: [
        RouterModule.forRoot(appRoutes),
        NgxDatatableModule,
        NoopAnimationsModule,
        BrowserModule,
        FormsModule,
        HttpModule,
        CalendarModule,
        MatFormFieldModule,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatInputModule,
        MatListModule,
        MatRadioModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatSidenavModule,
        MatSnackBarModule,
        MatTabsModule,
        MatToolbarModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        Daterangepicker,
        HttpClientModule,
        DragDropModule,
        MatIconModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        PinchZoomModule,
        PdfViewerModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            deps: [EnvConfigurationService],
            multi: true
        },
        SessionService,
        LoginService,
        HttpService,
        DevicesService,
        ProductService,
        PluginService,
        TaskService,
        ControlService,
        ThemeService,
        CompanyService,
        NavbarService,
        UiService,
        ActiveFiltersService,
        MqttService,
        SnackbarService,
        FilterArrayPipe,
        StatsService,
        StopService,
        OrderService,
        WorkProcessService,
        LocalStorageUtils,
        UserUtils,
        MqttUtils
    ],
    bootstrap: [AppComponent],
    entryComponents: [ PopupErrorComponent, PopupNotesComponent]
})
export class AppModule { }
