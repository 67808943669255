import { Injectable } from '@angular/core';
import { Subject } from "rxjs/Subject";
import {forEach} from "@angular/router/src/utils/collection";
import { Title } from '@angular/platform-browser';

@Injectable()
export class ThemeService {

  private isTeamSystemTheme: boolean;

  constructor(private titleService: Title) {
  }

  setTeamSystemTheme(isTeamSystemTheme: boolean): void {
    this.isTeamSystemTheme = isTeamSystemTheme;
    let theme = isTeamSystemTheme ? 'team-system' : 'techmass';
    this.setStyle('theme', `/assets/css/${theme}-theme.css`);
    this.setFavicon(theme);
    this.setTitle(theme);
  }

  setTitle(theme: string) {
    let title = "";
    switch (theme) {
      case "team-system":
        title = "Smart TV - MES Industry 4.0";
        break;
      default:
        title = "Smart TV - TechMass";
        break;
    }
    this.titleService.setTitle(title);
  }

  isTeamSystemThemeSetted(): boolean {
    return this.isTeamSystemTheme;
  }

  setStyle(key: string, href: string) {
    getLinkElementForKey(key).setAttribute('href', href);
  }

  faviconIds: string[] = [
    "apple-icon-57",
    "apple-icon-60",
    "apple-icon-72",
    "apple-icon-76",
    "apple-icon-114",
    "apple-icon-120",
    "apple-icon-144",
    "apple-icon-152",
    "apple-icon-180",
    "android-icon-192",
    "favicon-32",
    "favicon-96",
    "favicon-16",
    "manifest"
  ];

  setFavicon(theme: string) {
    let faviconDir = '/favicons';
    let directory= theme.replace('-', '_');
    if(directory == 'techmass') {
      directory = '';
    }
    let regexp = new RegExp('');

    this.faviconIds.forEach((id) => {
      let favIcon: HTMLLinkElement = document.querySelector('#' + id);
      let basePath = faviconDir;
      if(favIcon) {
        let change = false;
        if(directory === '') {
          let matches = favIcon.href.match(/(?<=favicons\/)(\w+)(?=\/)/);
          change = matches != null && matches.length > 0
          if(change) {
            basePath += '/' + matches[0] + '/';
          }
        } else {
          change = !favIcon.href.includes(directory);
        }
        if(change) {
          favIcon.href = favIcon.href.replace(basePath, faviconDir + '/' + directory);
        }
      }
    });
  }

  /**
   * Remove the stylesheet with the specified key.
   */
  removeStyle(key: string) {
    const existingLinkElement = getExistingLinkElementByKey(key);
    if (existingLinkElement) {
      document.head.removeChild(existingLinkElement);
    }
  }
}

function getLinkElementForKey(key: string) {
  return getExistingLinkElementByKey(key) || createLinkElementWithKey(key);
}

function getExistingLinkElementByKey(key: string) {
  return document.head.querySelector(`link[rel="stylesheet"].${getClassNameForKey(key)}`);
}

function createLinkElementWithKey(key: string) {
  const linkEl = document.createElement('link');
  linkEl.setAttribute('rel', 'stylesheet');
  linkEl.setAttribute('type', 'text/css');
  linkEl.classList.add(getClassNameForKey(key));
  document.head.appendChild(linkEl);
  return linkEl;
}

function getClassNameForKey(key: string) {
  return `style-manager-${key}`;
}
