import {TranslateService} from '@ngx-translate/core';
import {Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {Device} from "../../../models/device";
import {ProductDetailBarComponent} from "../product-detail-bar/product-detail-bar.component";
import {Timer} from "../device-box/Timer";
import * as moment from "moment";
import {StopService} from "../../../services/stop-history.service";
import {SessionService} from "../../../services/session.service";
import { Observable, observable } from 'rxjs';
import { DevicesService } from 'app/services/devices.service';
import { OrderService } from '../../../services/order.service';
import { Session } from '../../../models/session';

@Component({
    selector: 'device-changeover',
    templateUrl: 'device-changeover.component.html',
    styleUrls: ['./device-changeover.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DeviceChangeoverComponent implements OnInit, OnDestroy {
    @Input() device: Device;
    @ViewChild('productDetailBar') productDetailBar: ProductDetailBarComponent;

    order: string = undefined;
    productCode: string = undefined;

    targetView: boolean = false;
    sessionEmpty: boolean = false;
    sessions: Session[];

    changeOverTargetClock: number;
    changeOverCompletition: number;
    changeOverClock: number;

    changeOverTimer: Timer;
    changeOverTargetTimer: string;

    constructor(
        private _translate: TranslateService,
        private _stopService: StopService,
        private _sessionService: SessionService,
        private _deviceService: DevicesService,
        private _orderService: OrderService
    ) {
        const oneSecond = 1000;
        this.changeOverTimer = new Timer({
            onStart: () => {
                let timeNow = moment().valueOf();
                let offset = 0
                if (this.sessions.length > 0) {
                    let lastSession = this.sessions[this.sessions.length - 1];
                    offset = moment(lastSession.endAt).valueOf();
                } else {
                    this.sessionEmpty = true;
                }
                this.changeOverClock = timeNow - offset;
            },
            onStop: () => {
                this.changeOverClock = -1;
            },
            beforeFirstStart: () => {
            },
            timeoutMs: oneSecond
        });
    }

    async ngOnInit() {
        await this.getDataDevice();
        Observable.interval(1000).subscribe(() => {
            if(this.changeOverCompletition < 100){
                this.changeOverCompletition = (this.changeOverClock / (this.changeOverTargetClock * 2)) * 100;
            }
        })
    }

    async ngOnDestroy() {
        this.changeOverTimer.reset();
    }

    async getDataDevice() {

        this.productDetailBar.nextOrder = null;
        this.productDetailBar.nextProductCode = null;
        let startDate = moment(new Date()).add(-4, 'days').toISOString();
        let endDate = moment(new Date()).toISOString();
        this.sessions = await this._sessionService.getAll(startDate, endDate, this.device.id, null);
        this.changeOverTimer.reset(true);

        if(this.sessions.length == 0) {
            this.sessionEmpty = true;
            return;
        }
        let lastSession = this.sessions[this.sessions.length - 1];
        const {Product} = lastSession;
        if(lastSession.orderObj && lastSession.orderObj.length > 0) {
            this.order = lastSession.orderObj[0].code;
        } else {
            this.order = null;
        }
        
        this.productCode = Product ? Product.code + ' - ' + Product.name : undefined;

        const { code, productCode } = this._deviceService.getDeviceDataFormatted(this.device);

        if (code) {
            this.productDetailBar.nextOrder = code;
        } 
        
        if (productCode) {
            this.productDetailBar.nextProductCode = productCode;
        }

        this.productDetailBar.code = this.order;
        this.productDetailBar.productCode = this.productCode;
        
        /**
         * For debug purpose
         */
        // this.targetView = true;
        // this.changeOverTargetClock = 7000000;
        // this.changeOverTargetTimer = moment.utc(this.changeOverTargetClock).format('HH:mm:ss');
        // this.changeOverCompletition = ((this.changeOverClock / (this.changeOverTargetClock * 2)) * 100);
    }
}
