import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MqttService} from '../../../services/mqtt.service';
import {DevicesService} from 'app/services/devices.service';
import { SessionService } from 'app/services/session.service';
import {Device} from "../../../models/device";
import {ActivatedRoute} from "@angular/router";
import {OeeParams} from "../../../models/stats";
import * as moment from "moment";
import {StatsService} from "../../../services/stats.service";
import {NavbarService} from "../../../services/navbar.service";
import { PageWithLoader } from '../page-with-loader';
import { ThemeService } from 'app/services/theme.service';
import { Stop } from 'app/models/stop';
import { StopService } from 'app/services/stop-history.service';
import { MqttUtils } from 'app/services/mqtt.utils';
import { SnackbarType } from 'app/models/snackbar';
import { SnackbarService } from 'app/services/snackbar.service';

import { IntervalObservable } from 'rxjs/observable/IntervalObservable';
import { Subscription } from 'rxjs';

const tenMinutes = 10 * 60 * 1000;

@Component({
    templateUrl: './device-details.component.html',
    styleUrls: ['./device-details.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DeviceDetailsComponent extends PageWithLoader implements OnInit, OnDestroy {
    device: Device = null;
    loading = false;
    oee: OeeParams;
    stop: Stop;
    idDevice: number;
    onMessage;

    private intervalSubscription: Subscription;

    constructor(
        private _deviceService: DevicesService,
        private _sessionService: SessionService,
        private _mqttService: MqttService,
        private _activeRoute: ActivatedRoute,
        private _statsService: StatsService,
        public navbar: NavbarService,
        private _stopService: StopService,
        private _mqttUtils: MqttUtils,
        private _snackbarService: SnackbarService,
        _themeService: ThemeService
    ) {
        super(_themeService);
    }

    async ngOnInit() {
        this.loading = true;
        this._activeRoute.params.subscribe(async (params) => {
            this.idDevice = params["id"];

            let result = await this._mqttService.connectWebUser();            
            if (!result) {
                this._snackbarService.showSnackbar('Error retrieving devices status.\nPlease try again later.', SnackbarType.error, { verticalPosition: 'bottom'});
            }
            await this.subscribeToDataChannel(1);
            await this.updateDeviceStatus();
            this.navbar.setTitle(this.device.label);

            await this.waitForChangeStatus();

            this.loading = false;
        });

        // Refresh the page every 10 minutes, so we avoid calling an API to update the OEE
        // see EMIC-171
        IntervalObservable.create(tenMinutes).subscribe(() => {
            location.reload();
        });
    }

    async subscribeToDataChannel(retry: number) {
        if(retry >= 10) {
            console.log("You have reached maximum number of retry without success");
            return;
        }
        if(!this._mqttService.isConnected()) {
            await this.sleep(2000);
            await this.subscribeToDataChannel(retry + 1);
        } else {
            console.log(`subscribe to ${this._mqttUtils.computeTopicForDevice(this.idDevice)}`);
            console.log(`subscribe to ${this._mqttUtils.computeTopicEndpoint(this.idDevice)}`);
            await this._mqttService.subscribe(this._mqttUtils.computeTopicForDevice(this.idDevice));
            await this._mqttService.subscribe(this._mqttUtils.computeTopicEndpoint(this.idDevice));
        }
    }

    sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async ngOnDestroy() {
        if (this.onMessage) {
            await this.onMessage.unsubscribe();
        }
        console.log(`unsubscribe to ${this._mqttUtils.computeTopicForDevice(this.idDevice)}`);
        console.log(`unsubscribe to ${this._mqttUtils.computeTopicEndpoint(this.idDevice)}`);
        await this._mqttService.unsubscribe(this._mqttUtils.computeTopicForDevice(this.idDevice));
        await this._mqttService.unsubscribe(this._mqttUtils.computeTopicEndpoint(this.idDevice));

        if (this.intervalSubscription) {
            this.intervalSubscription.unsubscribe();
        }
    }

    async updateDeviceStatus() {
        this.device = await this._deviceService.getDevice(this.idDevice);
        if (this.device.isProducing() || this.device.isStopped()) {
            this.oee = await this._statsService.getAggregateOee(this.device.getBegin(), moment().toISOString(), [this.device.id]);
            this.oee.oee *= 100;
        }
        if (this.device.isStopped()) {
            this.stop = await this._stopService.getActive(this.device.id);
        } else {
            this.stop = null;
        }
    }

    async waitForChangeStatus() {
        if(!this._mqttService.client) {
            return;
        }
        if (this.onMessage) {
            return;
        }
        this.onMessage = this._mqttService.onMqttMessage.subscribe(async (message) => {
            const topic = message.topic;
            const payload = message.payload;
            if (topic.includes(`endpoint/${this.device.id}`) && topic.includes("state")) {
                await this.updateDeviceStatus();
            } else if (topic === this._mqttUtils.computeTopicForDevice(this.device.id)) {
                const commandType = payload.readUIntBE(0, 1);
                if (commandType === 40) {
                    await this.updateDeviceStatus();
                }
            }
        });
        
    }
}
