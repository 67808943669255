import { Device } from '../models/device';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { Order } from 'app/models/order';
import { OrderGroup } from 'app/models/orderGroup';
import { EnvConfigurationService } from './env-config.service';
import { SessionService } from './session.service';
import { StopService } from './stop-history.service';
import { StopType } from 'app/models/stop';
import { SessionType } from 'app/models/session';

@Injectable()
export class DevicesService {
    devices: Device[];
    constructor(
        private httpService: HttpService,
        private _envSetting: EnvConfigurationService,
        private _sessionService: SessionService,
        private _stopService: StopService,
    ) { }

    /**
     * Method to get all devices
     *
     * @memberof DeviceService
     */
    async getDevices() {
        const url = `${this._envSetting.settings.apiBase}/device?populateCompany=true`;
        const response = await this.httpService
            .get(url)
            .toPromise();
        this.devices = this.httpService.responseToArray(response, Device);

        // sort alphanumeric label
        this.devices.sort((a, b) => {
            var nameA = a.label.toUpperCase(); // ignore upper and lowercase
            var nameB = b.label.toUpperCase(); // ignore upper and lowercase

            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0; // names must be equal
        });

        // sort companyId
        this.devices.sort((a, b) => {
            return a.companyId - b.companyId;
        })

        return this.devices;
    }

    /**
     * Method to get a device
     *
     * @memberof DeviceService
     */
    async getDevice(deviceId: number) {
        const url = `${this._envSetting.settings.apiV2Base}/device/${deviceId}?includePlugins=true&includeModules=true`;
        const response = await this.httpService
            .get(url)
            .toPromise();
        const device = this.httpService.responseToInstance(response, Device);
        const sessions = await this._sessionService.getDeviceActiveSessions(deviceId);
        if (sessions && sessions.length) {
            device.Sessions = sessions;
            device.Session = await this._sessionService.getSessionDetail(sessions[0].id, deviceId);
            device.Order = device.Session.Order;
            device.OrderGroup = device.Session.OrderGroup;
            device.Product = device.Session.Product;

            device.stopCounter = {
                notSpecified: 0,
                planned: 0,
                unplanned: 0,
            };
            const stops = await this._stopService.get(deviceId);
            device.downtime = 0;
            device.downtimeWithPlanned = 0;
            for (const stop of stops) {
                if (stop.type == StopType.notSpecified) {
                    device.stopCounter.notSpecified++;
                    if (stop.endAt) {
                        device.downtime += stop.totalTime;
                        device.downtimeWithPlanned += stop.totalTime;
                    }
                } else if (stop.Error) {
                    if (stop.Error.type === 0) {
                        device.stopCounter.unplanned++;
                        if (stop.endAt && device.sessionType !== SessionType.timerMode) {
                            device.downtimeWithPlanned += stop.totalTime;
                            device.downtime += stop.totalTime;
                        }
                    } else {
                        device.stopCounter.planned++;
                        if (stop.endAt && device.sessionType !== SessionType.timerMode) {
                            device.downtimeWithPlanned += stop.totalTime;
                        }
                    }
                    if (stop.endAt && device.sessionType === SessionType.timerMode) {
                        device.downtimeWithPlanned += stop.totalTime;
                        device.downtime += stop.totalTime;
                    }
                }
            }
        }
        return device;
    }

    /**
     * Method to get the device from the list with the given id
     *
     * @memberof DeviceService
     * @param {number} deviceId
     * @returns {Device}
     */
    getDeviceInfo(deviceId: number): Device {
        for (const device of this.devices) {
            if (device.id === deviceId) {
                return device;
            }
        }
        return null;
    }

    /**  
     * Method to get formatter data 
     * 
     * @memberof DeviceService
     * @param {Device} device
     * @param {Order} order
     * @returns {}
    */
    getDeviceDataFormatted(device: Device): {
        pieces: number, targetPieces: number, targetSpeed: number, code: string, productCode: string,
        startProduction: any, downTime: number, stopCount: number, isOrderGroup: boolean,
    } {
        const { Order, OrderGroup, Session, Product, stopCounter } = device;

        const sessionItems = (Session ? Session.items : 0);

        let previousItems = 0;
        let currentScraps = 0;
        if (Order){
            previousItems = (Session && Order.previousProgresses ? Order.previousProgresses.items || 0 : 0);
            currentScraps = (Session && Order.currentProgresses ? Order.currentProgresses.scraps || 0 : 0);
        } else if (OrderGroup) {
            previousItems = (Session && OrderGroup.previousProgresses ? OrderGroup.previousProgresses.items || 0 : 0);;
            currentScraps = (Session && OrderGroup.currentProgresses ? OrderGroup.currentProgresses.scraps || 0 : 0);            
        }
        const pieces = sessionItems + previousItems - (device.oneSignal ? currentScraps : 0);

        let targetPieces = null;
        if (Order)
            targetPieces = Order.target;
        else if (OrderGroup)
            targetPieces = OrderGroup.target;

        const targetSpeed = Session ? Session.targetSpeed * 60 : undefined;

        let code = undefined;
        if (Order)
            code = Order.code;
        else if (OrderGroup)
            code = OrderGroup.code;

        const productCode = Product ? Product.code + ' - ' + Product.name : undefined;
        const startProduction = Session ? new Date(Session.beginAt) : null;
        const downTime = device.downtimeWithPlanned;
        const stopCount = stopCounter ? stopCounter.notSpecified + stopCounter.planned + stopCounter.unplanned : 0;
        const isOrderGroup = !!OrderGroup;

        return { pieces, targetPieces, targetSpeed, code,  productCode, startProduction, downTime, stopCount, isOrderGroup }
    }

}
