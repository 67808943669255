import { Product } from './product';
import { Device } from './device';
import { Order } from './order';
import { User } from './user';
import { WorkProcessCurrentActivityLog } from './work-process';
import { OrderGroup } from './orderGroup';

export enum SessionType {
  startWithPiece = 0,
  startWithProduct = 1,
  timerMode = 2,
  instantChangeOver = 3
}

export class Session {
  id: number;
  productId?: number;
  deviceId: number;
  items: number;
  scraps: number;
  beginSchedule?: Date;
  beginAt: Date;
  createdAt: Date;
  endAt: Date;
  updatedAt: Date;
  lastData: Date;
  active: number;
  totalTime: number;
  itemsPerMinute: number;
  scrapsPercentage: number;
  Device: Device;
  Product: Product;
  isLast?: boolean;
  orderObj?: Order[];
  User?: User;
  itemsDeclared?: number;
  scrapsDeclared?: number;
  note?: string;
  WorkProcessCurrentActivityLog?: WorkProcessCurrentActivityLog;
  orderId?: number;
  Order?: Order;
  OrderGroup?: OrderGroup;
  targetSpeed?: number;
}


