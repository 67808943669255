import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Device } from 'app/models/device';
import { DocumentView } from 'app/models/document-viewer';
import { DevicesService } from 'app/services/devices.service';
import { PluginService } from 'app/services/plugin.service';
import { Plugin, PluginType } from 'app/models/plugin'
import { ControlService } from 'app/services/control.service';
import { Operator } from 'app/models/operator';
import { ControlHistory } from 'app/models/controlHistory';
import { ActivatedRoute, Router } from '@angular/router';
import { OperatorSessionService } from 'app/services/operator-session.service';
import { TaskHistory } from 'app/models/taskHistory';
import { TaskService } from 'app/services/task.service';
import { UiService } from 'app/services/ui.service';
import { PageWithLoader } from '../page-with-loader';
import { ThemeService } from 'app/services/theme.service';
import { ProductDetailBarComponent } from 'app/components/ui/product-detail-bar/product-detail-bar.component';
import { LocalStorageUtils } from 'app/services/localstorage.utils';

// interface DetailBar{
//   controlName: string,
//   order?: string,
//   productCode?: number,
// }

export interface TasksInformations{
  userId?: number,
  taskPage: number,
  valueCounter?: number,
  documentView?: DocumentView,
  availableData: boolean,

  taskHistoryList: TaskHistory[],
  taskList: any,
  taskParamHistoryList?: any;
  taskParamList?: any;
}

interface OperatorDetail{
  name: string,
  id: number
}

@Component({
  selector: 'app-control-details',
  templateUrl: './control-details.component.html',
  styleUrls: ['./control-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ControlDetailsComponent extends PageWithLoader implements OnInit, OnDestroy {

  @ViewChild('productDetailBar') productDetailBar: ProductDetailBarComponent;
  code: string = undefined;
  productCode: string = undefined;
  device: Device;
  documentView: DocumentView;
  controlId;
  loading = true;

  //Plugins

  selectedOperator;
  login_operator = false;
  order_plugin = false;

  //Logistic

  isTaskPage;
  controls = [];
  plugin = [];
  operators_list: OperatorDetail[] = []

  zoomLevel = 1;
  zoomScaleFactor = 0.1;

  taskContainer: TasksInformations

  constructor(
    public dialog: MatDialog,
    private _deviceService: DevicesService,
    private _pluginService: PluginService,
    private _controlService: ControlService,
    private _taskService: TaskService,
    private _operatorSession: OperatorSessionService,
    private _route: ActivatedRoute,
    private _uiService: UiService,
    private _router: Router,
    private _localStorageUtils: LocalStorageUtils,
    _themeService: ThemeService) {
      super(_themeService)
  }

  async ngOnInit() {

    this.taskContainer = {
      userId: null,
      taskPage: 0,
      valueCounter: 0,
      documentView: null,
      availableData: false,
      taskHistoryList: [],
      taskList: [],
      taskParamHistoryList: [],
      taskParamList:[]
    }

    await this.onFilterChanged();
  }

  ngOnDestroy() {
  }

  //Check and workflow functions

  progress() {
    this.login_operator = false;
  }

  check_control(controls: ControlHistory[]){
    controls.forEach(control => {
      if(control.id == this.controlId){
        this.productDetailBar.control = control.Control.description
      }
    })
  }

  check_plugin_operator(plugins: Plugin[]){
    let verify = false
    plugins.forEach(plugin => {
      if(plugin.code == PluginType.operatorLogin && plugin.PluginsDevices){
        if(plugin.PluginsDevices.enabled){
        verify = true;
        }
      }

    });
    return verify
  }

  createOperatorList(operatos: Operator[]){
    operatos.forEach(operator => {
      let tmp: OperatorDetail = {
        id: operator.id,
        name: "" + operator.name + " " + operator.surname
      }
      this.operators_list.push(tmp)
    })
    if(operatos.length > 0){
      this.selectedOperator = this.operators_list[0].id
    }
  }

  check_plugin_order(plugins: Plugin[]){
    let verify = false
    plugins.forEach(plugin => {
      if(plugin.code == PluginType.order && plugin.PluginsDevices){
        if(plugin.PluginsDevices.enabled){
        verify = true;
        }
      }

    });
    return verify
  }

  //Create task page attributes

  createTasks(){
    this.taskContainer.taskHistoryList.forEach(taskHistory => {
      this.taskContainer.taskList.push(taskHistory.Task);
      if(taskHistory.TasksParametersHistories){
      this.taskContainer.taskParamHistoryList.push(taskHistory.TasksParametersHistories);
      }
      else{
        this.taskContainer.taskParamHistoryList.push([]);
      }
    });
  }

  checkFirstTime(){
    this.taskContainer.taskHistoryList.forEach(taskHistory => {
      if(taskHistory.completed == false){
        taskHistory.compliant = true;
      }
    });
  }

  createParamTask(){
    this.taskContainer.taskList.forEach(task => {
      if(task.TasksParameters){
      this.taskContainer.taskParamList.push(task.TasksParameters);
      }else{
      this.taskContainer.taskParamList.push([]);
      }
    })
  }

  giveValue(){

    //Check if I need to give him value parameter if new type
    for(let index = 0; index < this.taskContainer.taskParamList.length; index++){
      if(this.taskContainer.taskParamList[index].length > 0){
        this.taskContainer.taskParamList[index].forEach(tmp => {
          if(this.taskContainer.taskParamHistoryList[index].length > 0){
            this.taskContainer.taskParamHistoryList[index].forEach(paramHistory => {
            if(tmp.id == paramHistory.taskParameterId){
              tmp.value = paramHistory.value ? paramHistory.value : null
            }
            });

          }
          else{
            tmp.value = null;
          }

        });
      }
      else{
        this.taskContainer.taskHistoryList[index].value == null ? this.taskContainer.taskHistoryList[index].value = " " : this.taskContainer.taskHistoryList[index].value
      }
    }
    }

  check_limit_value_done(){
    
    if(this.taskContainer.taskParamList[this.taskContainer.taskPage].length > 0){
    this.taskContainer.taskParamList[this.taskContainer.taskPage].forEach(tmp => {
          if(!(this.taskContainer.valueCounter > this.taskContainer.taskParamList.length)){
            tmp.value != null ? this.taskContainer.valueCounter++ : this.taskContainer.valueCounter
          }
  });
}else{
this.taskContainer.taskHistoryList[this.taskContainer.taskPage].value && this.taskContainer.taskHistoryList[this.taskContainer.taskPage].value != " "? this.taskContainer.valueCounter = 1 : this.taskContainer.valueCounter = 0;
}
}

redirect() {
  this._router.navigateByUrl("qualitycontrol");
}

async getDataDevice() {

  const { code, productCode } = this._deviceService.getDeviceDataFormatted(this.device);
  this.productDetailBar.code = this.code = code;
  this.productDetailBar.productCode = this.productCode = productCode;
}


 /**
 * retrieve documents for task
 *
 * @memberof DocumentViewComponent
 */

  async buildPdf (filename, taskId) {
    let buff = null;
    try {
        this.loading = true;

        buff = await this._taskService.getPDFTasks(taskId);
        if (buff && buff._body) {
            let file = new Blob([buff._body], { type: 'application/blob' });
            var fileURL = URL.createObjectURL(file);
           this.taskContainer.documentView = new DocumentView(true, fileURL);
           this.taskContainer.availableData = true;
        }

        this.loading = false;
    } catch (err) {
        console.log("Error : retriving file "+ filename);
        this.loading = false;
        this._uiService.openErrorSnackBar("Error : retriving file " + filename, "Ok")
    }
}

  async onFilterChanged() {
    try {
      const devId = this._localStorageUtils.deviceId;
      this.device = await this._deviceService.getDevice(devId);
      this.plugin = await this._pluginService.getPlugins(this.device.id);
      this.login_operator = this.check_plugin_operator(this.plugin);
      this.order_plugin = this.check_plugin_order(this.plugin);
      await this.getDataDevice();
      this._route.params.subscribe(params => {
        this.controlId = params.id;
        }
        );
      this.controls = await this._controlService.getControls(devId);
      this.check_control(this.controls);
      this.createOperatorList(await this._operatorSession.getSessionOperators(devId) );
      this.taskContainer.taskHistoryList = await this._taskService.getTasks(this.controlId, this.device.id);
      this.createTasks();
      this.checkFirstTime()
      this.createParamTask();
      this.giveValue();
      this.check_limit_value_done();
      this.isTaskPage = true;
  
      if(this.taskContainer.taskHistoryList[0].Task.docLink){
        this.buildPdf(this.taskContainer.taskHistoryList[0].Task.docLink, this.taskContainer.taskHistoryList[0].Task.id)
        }
      this.loading = false;
    } catch (error) {
      this._uiService.openErrorSnackBar("Error : in retrieving data for this task. \n" + error, "Ok");
      this.loading = false;
      this.redirect();
    }
  
  }

}
