import {TranslateService} from '@ngx-translate/core';
import {Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {Device} from "../../../models/device";
import { ProductDetailBarComponent } from '../product-detail-bar/product-detail-bar.component';
import {Timer} from "../device-box/Timer";
import * as moment from "moment";
import {StopService} from "../../../services/stop-history.service";
import {SessionService} from "../../../services/session.service";
import { DevicesService } from 'app/services/devices.service';
import { OrderService } from '../../../services/order.service';
import { Session } from '../../../models/session';

@Component({
    selector: 'device-endproduction',
    templateUrl: 'device-endproduction.component.html',
    styleUrls: ['./device-endproduction.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DeviceEndproductionComponent implements OnInit, OnDestroy {
    @Input() device: Device;
    @ViewChild('productDetailBar') productDetailBar: ProductDetailBarComponent;

    order: string = undefined;
    productCode: string = undefined;

    sessionEmpty: boolean = false;
    sessions: Session[];

    endProductionClock: number;
    endProductionTimer: Timer;

    constructor(
        private _translate: TranslateService,
        private _stopService: StopService,
        private _sessionService: SessionService,
        private _deviceService: DevicesService,
        private _orderService: OrderService
    ) {
        const oneSecond = 1000;
        this.endProductionTimer = new Timer({
            onStart: () => {
                const timeNow = moment().valueOf();
                let offset = 0;
                if (this.sessions.length > 0) {
                    const lastSession = this.sessions[this.sessions.length - 1];
                    offset = moment(lastSession.endAt).valueOf();
                } else {
                    this.sessionEmpty = true;
                }
                this.endProductionClock = timeNow - offset;
            },
            onStop: () => {
                this.endProductionClock = -1;
            },
            beforeFirstStart: () => {
            },
            timeoutMs: oneSecond
        });
    }

    async ngOnInit() {
        await this.getDataDevice();
    }

    async ngOnDestroy() {
        this.endProductionTimer.reset();
    }

    async getDataDevice() {
        this.productDetailBar.nextOrder = null;
        this.productDetailBar.nextProductCode = null;
        const startDate = moment(new Date()).add(-4, 'days').toISOString();
        const endDate = moment(new Date()).toISOString();
        this.sessions = await this._sessionService.getAll(startDate, endDate, this.device.id, null);
        this.endProductionTimer.reset(true);

        if (this.sessions.length == 0) {
            this.sessionEmpty = true;
            return;
        }
        const lastSession = this.sessions[this.sessions.length - 1];
        const { Product } = lastSession;
        if (lastSession.orderObj && lastSession.orderObj.length > 0) {
            this.order = lastSession.orderObj[0].code;
        } else {
            this.order = null;
        }
        
        this.productCode = Product ? Product.code + ' - ' + Product.name : undefined;

        const { code, productCode } = this._deviceService.getDeviceDataFormatted(this.device);

        if (code) {
            this.productDetailBar.nextOrder = code;
        } 
        
        if (productCode) {
            this.productDetailBar.nextProductCode = productCode;
        }

        this.productDetailBar.code = this.order;
        this.productDetailBar.productCode = this.productCode;

    }
}