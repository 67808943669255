import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { Device } from "../../../models/device";
import { MqttService } from "../../../services/mqtt.service";
import { ProductDetailBarComponent } from "../product-detail-bar/product-detail-bar.component";
import { OeeParams } from "../../../models/stats";
import { DevicesService } from "../../../services/devices.service";
import * as moment from "moment";
import { Timer } from "../device-box/Timer";
import { Observable } from 'rxjs/Rx';
import { StatsService } from '../../../services/stats.service';

const oneSecond = 1000;
const tenMinutes = 10 * 60 * 1000;

@Component({
    selector: 'device-producing',
    templateUrl: 'device-producing.component.html',
    styleUrls: ['./device-producing.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DeviceProducingComponent implements OnInit, OnDestroy {
    @Input() device: Device;
    @Input() mqttService: MqttService;
    @Input() oee: OeeParams;
    @ViewChild('productDetailBar') productDetailBar: ProductDetailBarComponent;

    defaultPercentage = 70;

    orderCode: string = undefined;
    productCode: string = undefined;

    downTime: number = 0;

    pieces: number = 0;
    percentage?: number = undefined;
    targetPieces?: number = undefined;
    stopCount: number = 0;

    productionTimer: Timer;
    productionClock = -1;
    productionBeginAt;

    targetSpeed: number = 0;
    realSpeed: number = 0;

    startProduction;
    timeProduction;
    targetEndProduction;
    realEndProduction;
    onMessage;

    constructor(
        private _deviceService: DevicesService,
        private _statsService: StatsService,
    ) {

        //     this.productionTimer = new Timer({
        //         onStart: () => {
        //             this.productionClock += oneSecond;
        //         },
        //         onStop: () => this.productionClock = -1,
        //         beforeFirstStart: () => {
        //             if (this.device.isProducing()) {
        //                 const { totalTime } = this.device.Session;
        //                 const { downtimeWithPlanned } = this.device;
        //                 this.productionClock = moment(totalTime).valueOf() - moment(downtimeWithPlanned).valueOf();
        //             }
        //         },
        //         timeoutMs: oneSecond
        //     });

        this.productionTimer = new Timer({
            onStart: () => {
                this.productionClock = (moment().valueOf() - moment(this.productionBeginAt).valueOf()) - moment(this.device.downtimeWithPlanned).valueOf();
            },
            onStop: () => this.productionClock = -1,
            beforeFirstStart: () => {
                if (this.device.isProducing()) {
                    const { totalTime } = this.device.Session;
                    this.productionBeginAt = moment().valueOf() - moment(totalTime).valueOf();
                }
            },
            timeoutMs: oneSecond
        });
    }

    async ngOnInit() {
        this.productDetailBar.restart();
        this.getDataDevice();
        await this.waitForPieces(1);

        Observable.interval(1000).subscribe(() => {
            this.timeProduction += 1000;
        });
    }

    async ngOnDestroy() {
        this.productionTimer.reset();
        if (this.onMessage) {
            this.onMessage.unsubscribe()
        }
    }

    sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async waitForPieces(retry: number) {
        if (retry >= 10) {
            console.log("You have reached maximum number of retry without success");
            return;
        }
        if (!this.mqttService.client) {
            await this.sleep(2000);
            await this.waitForPieces(retry + 1);
            return;
        }
        if (this.onMessage) {
            return;
        }
        this.onMessage = this.mqttService.onMqttMessage.subscribe(async (message) => {
            const topic = message.topic;
            const payload = message.payload;
            if (topic.includes(`endpoint/${this.device.id}`) && topic.includes("pieces")) {
                const message = JSON.parse(payload.toString());
                this.updateValue(true, message.value);
            }
        });
    }

    updateValue(newPiece: boolean, count: number) {
        if (newPiece) {
            this.pieces += count;
            this.device.Session.items += count;
        }
        if (this.targetPieces) {
            this.percentage = Math.round((this.pieces * 100) / this.targetPieces);
        }

        this.timeProduction = moment().valueOf() - moment(this.device.Session.beginAt).valueOf()
        let productionTimeElapsed = moment(this.timeProduction).diff(this.device.downtime) / (1000 * 60);
        this.realSpeed = Math.round((this.device.Session.items / productionTimeElapsed) * 60);
        this.realEndProduction = moment().add((((this.targetPieces - this.pieces) / (this.realSpeed) * 60 * 60 * 1000)), 'milliseconds').toDate();
        this.targetEndProduction = this.targetPieces && this.targetSpeed ? moment(this.startProduction).add(Math.round(this.targetPieces / (this.targetSpeed / 60)), 'minutes').toDate() : this.realEndProduction;
    }

    getMultiplier() {
        let multiplier = this.device.multiplier || 1;
        if (this.device.Product && this.device.Product.multiplier) {
            multiplier = this.device.Product.multiplier;
        }
        return multiplier;
    }

    async getDataDevice() {

        const {
            pieces,
            targetPieces,
            targetSpeed,
            code,
            productCode,
            startProduction,
            downTime,
            stopCount,
            isOrderGroup
        } = this._deviceService.getDeviceDataFormatted(this.device);

        this.pieces = pieces; this.targetPieces = targetPieces; this.targetSpeed = targetSpeed;
        this.startProduction = startProduction; this.downTime = downTime; this.stopCount = stopCount;

        this.productDetailBar.code = this.orderCode = code;
        this.productDetailBar.productCode = this.productCode = productCode;
        this.productDetailBar.isOrderGroup = isOrderGroup;

        if (!this.targetPieces) {
            this.percentage = this.defaultPercentage;
        }

        this.timeProduction = this.device.Session.totalTime;
        this.updateValue(false, 0);

        this.productionTimer.reset(this.device.isTimerMode());
    }

}
