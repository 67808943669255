import {LoginService} from './login.service';
import {HttpService} from './http.service';
import {Injectable} from '@angular/core';
import {Stop} from '../models/stop';

import 'rxjs/add/operator/toPromise';
import { EnvConfigurationService } from './env-config.service';


@Injectable()
export class StopService {
    constructor(
        private http: HttpService,
        private _envSetting: EnvConfigurationService
    ) { }

    async get(deviceId: number): Promise<Stop[]> {
        const url = `${this._envSetting.settings.apiV2Base}/stops/device/${deviceId}`;

        const response = await this.http
            .get(url)
            .toPromise();
        return this.http.responseToArray(response, Stop);
    }

    getActive(deviceId: number): Promise<Stop> {
        const url = `${this._envSetting.settings.apiBase}/stopHistory/active?deviceId=${deviceId}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response.json() as Stop);
    }
}
