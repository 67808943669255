import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { Session } from 'app/models/session';

import 'rxjs/add/operator/toPromise';
import { EnvConfigurationService } from './env-config.service';


@Injectable()
export class SessionService {
  constructor(
    private http: HttpService,
    private _envSetting: EnvConfigurationService) { }
    
  getAll(startDate: string, endDate: string, deviceId: number, productId: number): Promise<Session[]> {
    let url;
    if (!startDate || !endDate) {
      url = `${this._envSetting.settings.apiBase}/session`;
    } else {
      url = `${this._envSetting.settings.apiBase}/session?fromDate=${startDate}&toDate=${endDate}&deviceId=${deviceId}`;
    }

    if (productId) {
      url += `&productId=${productId}`;
    }

    return this.http
      .get(url)
      .toPromise()
      .then(response => response.json() as Session[]);
  }
  update(sessionId: number, itemsDeclared: number, scrapsDeclared: number, note: string): Promise<Session> {
    let url = `${this._envSetting.settings.apiBase}/session/${sessionId}`;
    let obj = {};
    if (itemsDeclared || itemsDeclared === 0) obj['itemsDeclared'] = itemsDeclared;
    if (scrapsDeclared || scrapsDeclared === 0) obj['scrapsDeclared'] = scrapsDeclared;
    if (note || (note !== null && note !== undefined)) obj['note'] = note;

    return this.http
      .put(url, obj)
      .toPromise()
      .then(response => response.json() as Session);
  }

  async getSessionDetail(sessionId: number, deviceId: number) {
    const url = `${this._envSetting.settings.apiV2Base}/session/${sessionId}?deviceId=${deviceId}`;
    const response = await this.http
        .get(url)
        .toPromise();
    return this.http.responseToInstance(response, Session);
  }

  async getDeviceActiveSessions(deviceId: number) {
    const url = `${this._envSetting.settings.apiV2Base}/sessions/active/device/${deviceId}`;
    const response = await this.http
        .get(url)
        .toPromise();
    return this.http.responseToArray(response, Session);
  }

}
