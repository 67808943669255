import { Stats, OeeParams, StopsStats } from '../models/stats';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { ProductionSchedule } from 'app/models/schedules';
import { LocalStorageUtils } from './localstorage.utils';
import { EnvConfigurationService } from './env-config.service';

@Injectable()
export class StatsService {
    constructor(private httpService: HttpService,
        private _localStorageUtils: LocalStorageUtils,
        private _envSetting: EnvConfigurationService) { }

    /**
     * Method to get stats
     *
     * @memberof StatsService
     */
    async getStats(startDate: string, endDate: string, deviceId: number, productId: number, stopType?: number, errorId?: number, granularity?: number, schedules?: string): Promise<Stats> {
        let url = `${this._envSetting.settings.apiBase}/dataStats?fromDate=${startDate}&toDate=${endDate}&deviceId=${deviceId}`;

        if (productId) {
            url += `&productId=${productId}`;
        }
        if (stopType || stopType === 0) {
            url += `&stopType=${stopType}`;
        }
        if (errorId) {
            url += `&errorId=${errorId}`;
        }
        if (granularity) {
            url += `&granularity=${granularity}`;
        }
        if (schedules && schedules.length) {
            url +=  `&shifts=${schedules}`
        }

        const response = await this.httpService
            .get(url)
            .toPromise();
        return response.json() as Stats;
    }


    /**
     * Method to get stats with granularity
     *
     * @memberof StatsService
     */
    async getAllStats(startDate: string, endDate: string, deviceId: number, productId: number, granularity?: number): Promise<Stats[]> {
        let url = `${this._envSetting.settings.apiBase}/dataStats?fromDate=${startDate}&toDate=${endDate}&deviceId=${deviceId}`;

        if (productId) {
            url += `&productId=${productId}`;
        }

        if (granularity) {
            url += `&granularity=${granularity}`;
        }

        const response = await this.httpService
            .get(url)
            .toPromise();
        return response.json() as Stats[];
    }


    /**
     * Method to get oee
     * DISMISSED
     * @memberof StatsService
     */
    
    async getOee(startDate: string, endDate: string, deviceId: number, productId?: number, considerPlanned?: boolean, considerChangeOver?: boolean, schedules?: string): Promise<OeeParams> {
        let url = `${this._envSetting.settings.apiBase}/dismissed/oeeStats?fromDate=${startDate}&toDate=${endDate}&deviceId=${deviceId}`;

        if (productId) {
            url += `&productId=${productId}`;
        }
        let oae = this._localStorageUtils.readFromLocalStorage('oae') && this._localStorageUtils.readFromLocalStorage('oae') === 'true' ? true : false;
        if (oae) {
            url += `&considerPlanned=false`;
        } else if (considerPlanned) {
            url += `&considerPlanned=${considerPlanned}`;
        } else if (this._localStorageUtils.readFromLocalStorage('considerPlannedInOEE')) {
            const considerPlannedStorage = JSON.parse(this._localStorageUtils.readFromLocalStorage('considerPlannedInOEE'));
            url += `&considerPlanned=${considerPlannedStorage}`;
        }

        if (!oae && considerChangeOver) {
            url += `&considerChangeOver=${considerChangeOver}`;
        } else if (this._localStorageUtils.readFromLocalStorage('considerChangeOver')) {
            const considerChangeOverStorage = JSON.parse(this._localStorageUtils.readFromLocalStorage('considerChangeOver'));
            url += `&considerChangeOver=${considerChangeOverStorage}`;
        }

        if (schedules && schedules.length) {
            url +=  `&shifts=${schedules}`
        }

        const response = await this.httpService
            .get(url)
            .toPromise();
        return response.json() as OeeParams;
    }

    /**
     * Method to get oee
     *
     * @memberof StatsService
     */

    async getAggregateOee(startDate: string, endDate: string, deviceIds: number[], productId?: number, considerPlanned?: boolean, considerChangeOver?: boolean, schedules?: string): Promise<OeeParams> {
        let url = `${this._envSetting.settings.apiBase}/oeeStats?fromDate=${startDate}&toDate=${endDate}&deviceIds=${JSON.stringify(deviceIds)}&disableWorkShiftReport=true`;

        if (productId) {
            url += `&productId=${productId}`;
        }
        let oae = this._localStorageUtils.readFromLocalStorage('oae') && this._localStorageUtils.readFromLocalStorage('oae') === 'true' ? true : false;
        if (oae) {
            url += `&considerPlanned=false`;
        } else if (considerPlanned) {
            url += `&considerPlanned=${considerPlanned}`;
        } else if (this._localStorageUtils.readFromLocalStorage('considerPlannedInOEE')) {
            const considerPlannedStorage = JSON.parse(this._localStorageUtils.readFromLocalStorage('considerPlannedInOEE'));
            url += `&considerPlanned=${considerPlannedStorage}`;
        }

        if (!oae && considerChangeOver) {
            url += `&considerChangeOver=${considerChangeOver}`;
        } else if (this._localStorageUtils.readFromLocalStorage('considerChangeOver')) {
            const considerChangeOverStorage = JSON.parse(this._localStorageUtils.readFromLocalStorage('considerChangeOver'));
            url += `&considerChangeOver=${considerChangeOverStorage}`;
        }

        if (schedules && schedules.length) {
            url +=  `&shifts=${schedules}`
        }

        const response = await this.httpService
            .get(url)
            .toPromise();
        return response.json() as OeeParams;
    }


    /**
     * Method to get oee
     *
     * @memberof StatsService
     */
    async getWaterfall(startDate: string, endDate: string, deviceId: number, productId?: number): Promise<StopsStats> {
        let url = `${this._envSetting.settings.apiBase}/waterfall?fromDate=${startDate}&toDate=${endDate}&deviceId=${deviceId}`;

        if (productId) {
            url += `&productId=${productId}`;
        }

        const response = await this.httpService
            .get(url)
            .toPromise();
        return response.json() as StopsStats;
    }

    async getSchedules(deviceId: number, startDate: string, endDate: string) {
        const url = `${this._envSetting.settings.apiBase}/device/${deviceId}/shiftInterval?fromDate=${startDate}&toDate=${endDate}`;
        const response = await this.httpService
            .get(url)
            .toPromise();
        return response.json() as ProductionSchedule[];
    }
}

